export default class BgSlider {
  constructor() {
    this.thumbnails = document.querySelectorAll('.homepage__bg-slider-item');
    if (this.thumbnails.length > 0) this.init();
  }

  init() {
    this.activeBg = 1;
    this.buttons = document.querySelectorAll('.homepage__bg-slider-btn');
    this.btnPrev = document.querySelector('.homepage__bg-slider-btn--prev');
    this.btnNext = document.querySelector('.homepage__bg-slider-btn--next');
    this.backgrounds = document.querySelectorAll('.homepage__backgrounds img');
    this.addEventListeners();
    this.changeBgInterval = setInterval(() => {
      this.setNextActiveIndex();
    }, 4000);
  }

  setNextActiveIndex() {
    if (this.activeBg < this.thumbnails.length) {
      this.activeBg++;
      this.setActiveThumbnail();
    } else {
      this.activeBg = 1;
      this.setActiveThumbnail();
    }
  }

  addEventListeners() {
    this.btnPrev.addEventListener('click', () => {
      if (this.activeBg > 1) {
        this.activeBg--;
        this.setActiveThumbnail();
        clearInterval(this.changeBgInterval);
      }
    });
    this.btnNext.addEventListener('click', () => {
      if (this.activeBg < this.thumbnails.length) {
        this.activeBg++;
        this.setActiveThumbnail();
        clearInterval(this.changeBgInterval);
      }
    });
    this.thumbnails.forEach((thumbnail) => {
      thumbnail.addEventListener('click', (e) => {
        this.activeBg = e.target.getAttribute('data-bg-index');
        this.setActiveThumbnail();
        clearInterval(this.changeBgInterval);
      });
    });
  }

  setActiveThumbnail() {
    this.thumbnails.forEach((thumbnail) => {
      thumbnail.classList.remove('active');
      if (this.activeBg == thumbnail.getAttribute('data-bg-index')) {
        thumbnail.classList.add('active');
        this.setBackground();
      }
    });
  }

  setBackground() {
    this.backgrounds.forEach((background) => {
      background.classList.remove('active');
    });
    this.backgrounds[this.activeBg - 1].classList.add('active');
  }
}
