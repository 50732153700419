export default class Menu {
  constructor() {
    this.menu = document.querySelector('.menu');
    this.menuToggle = document.querySelectorAll('.menu__toggle');
    this.menuToggle.forEach((toggle) => {
      toggle.addEventListener('click', () => {
        this.menu.classList.toggle('visible');
      });
    });
  }
}
