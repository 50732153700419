export default class SingleProject {
  constructor() {
    this.singleProjectSlides = [...document.querySelectorAll('.p-single-project__slide')];

    if (this.singleProjectSlides.length > 0) {
      this.init();
      this.keyboardEvents();
      this.setTotalSlides();
    }
  }

  init() {
    this.activeSlide = 1;
    this.buttons = document.querySelectorAll('.p-single-project__nav-btn');
    this.btnPrev = document.querySelector('.p-single-project__nav-btn--left');
    this.btnNext = document.querySelector('.p-single-project__nav-btn--right');
    this.overlay = document.querySelector('.p-single-project__overlay');
    this.backgrounds = document.querySelectorAll('.p-single-project__background-image');

    this.btnPrev.addEventListener('click', (e) => {
      if (this.activeSlide <= this.singleProjectSlides.length && this.activeSlide > 1) {
        this.activeSlide--;
      }
    });

    this.btnNext.addEventListener('click', (e) => {
      if (this.activeSlide > 0 && this.activeSlide < this.singleProjectSlides.length) {
        this.activeSlide++;
      }
    });

    this.buttons.forEach((btn) => {
      btn.addEventListener('click', () => {
        this.changeSlide();
      });
    });
  }

  changeSlide() {
    this.setCurrentSlideIndex();

    if (this.activeSlide > 1) {
      this.overlay.classList.remove('visible');
    } else {
      this.overlay.classList.add('visible');
    }

    this.backgrounds.forEach((background) => {
      background.classList.remove('visible');
      if (this.activeSlide == background.getAttribute('data-slide-image')) {
        background.classList.add('visible');
      }
    });

    this.singleProjectSlides.forEach((slide) => {
      slide.classList.remove('visible');
      if (this.activeSlide == slide.getAttribute('data-slide')) {
        slide.classList.add('visible');
      }
    });

    if (this.activeSlide == this.singleProjectSlides.length) {
      this.btnNext.style.visibility = 'hidden';
    } else {
      this.btnNext.style.visibility = 'visible';
    }

    if (this.activeSlide == 1) {
      this.btnPrev.style.visibility = 'hidden';
    } else {
      this.btnPrev.style.visibility = 'visible';
    }
  }

  keyboardEvents() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 39) {
        if (this.activeSlide > 0 && this.activeSlide < this.singleProjectSlides.length) {
          this.activeSlide++;
          this.changeSlide();
        }
      }
      if (e.keyCode == 37) {
        if (this.activeSlide <= this.singleProjectSlides.length && this.activeSlide > 1) {
          this.activeSlide--;
          this.changeSlide();
        }
      }
    });
  }

  setTotalSlides() {
    const totalSlides = document.querySelector('span.p-single-project__nav-totalSlides');
    totalSlides.innerText = this.singleProjectSlides.length;
  }

  setCurrentSlideIndex() {
    const currentSlideIndicator = document.querySelector('span.p-single-project__nav-currentSlide');
    currentSlideIndicator.innerText = this.activeSlide;
  }
}
