'use strict';

import Menu from './includes/menu';
import Loading from './includes/loading';
import Scroller from './includes/scroller';
import SingleProject from './includes/single-project';
import BgSlider from './includes/bg-slider';

document.addEventListener('DOMContentLoaded', () => {
  new Menu();
  new Loading();
  new Scroller();
  new SingleProject();
  new BgSlider();
});
