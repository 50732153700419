import { gsap } from 'gsap';

export default class Loading {
  constructor() {
    this.loadingScreen = document.getElementById('LoadingScreen');
    if (this.loadingScreen) this.init();
  }

  init() {
    let tl = gsap.timeline();
    tl.fromTo('.loading-screen__logo', { autoAlpha: 0 }, { autoAlpha: 1, duration: 1, delay: 1 });
    tl.to('.loading-screen__logo', { autoAlpha: 0, duration: 1, delay: 1 });
    tl.to('.loading-screen__logo', { display: 'none' });
    tl.to('.loading-screen__quote', { display: 'block' });
    tl.fromTo('.loading-screen__quote', { autoAlpha: 0 }, { autoAlpha: 1, duration: 2, delay: 0 });
    tl.to('.loading-screen', { autoAlpha: 0, duration: 1 });
    tl.to('.loading-screen', { display: 'none' });

    document.cookie = 'loading=seen; expires=' + new Date(86400000 + Date.now()).toUTCString() + ';';
  }
}
