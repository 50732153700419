export default class Scroller {
  constructor() {
    this.scrollContainer = document.querySelector('.p-archive-project__wrapper');
    this.sections = document.querySelectorAll('.p-scroll__section');

    if (this.scrollContainer) this.initArchiveProjects();
    if (this.sections.length > 0) this.init();
  }

  init() {
    this.slides = document.querySelectorAll('.p-scroll__slide-text');
    this.background = document.querySelector('.p-scroll__background');
    this.backgroundImages = document.querySelectorAll('.p-scroll__background-image');
    this.scroller = document.querySelector('.p-scroll__scroller');
    this.overlay = document.querySelector('.p-scroll__overlay');
    this.scrollOffset = 100;

    this.activeSection = 1;
    this.buttons = document.querySelectorAll('.p-scroll__nav-btn');
    this.btnUp = document.querySelector('.p-scroll__nav-btn--up');
    this.btnDown = document.querySelector('.p-scroll__nav-btn--down');
    this.dots = document.querySelectorAll('.dot');
    this.overlayActive = false;
    this.overlayRange = [];

    this.keyboardEvents();
    this.calculateRange();

    window.addEventListener('resize', () => {
      this.calculateRange();
    });

    this.scroller.addEventListener('scroll', () => {
      let pagePos = this.scroller.scrollTop;

      this.sections.forEach((section, index) => {
        let elTop = this.sections[index].offsetTop;
        if (pagePos >= elTop - this.scrollOffset) {
          this.backgroundImages[index].classList.add('visible');
        } else {
          this.backgroundImages[index].classList.remove('visible');
        }
      });

      for (let index = 0; index < this.overlayRange.length; index++) {
        if (pagePos > this.overlayRange[index].start && pagePos < this.overlayRange[index].end) {
          this.overlay.classList.add('visible');
          break;
        }
        this.overlay.classList.remove('visible');
      }

      if (!document.body.classList.contains('about-us')) {
        if (this.scroller.offsetHeight + this.scroller.scrollTop >= this.scroller.scrollHeight - this.scrollOffset) {
          this.overlay.classList.add('dark');
        } else {
          this.overlay.classList.remove('dark');
        }
      }

      this.sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (pagePos >= sectionTop - this.scrollOffset) {
          this.activeSection = section.getAttribute('data-section');
          this.changeDots();
        }
      });
    });

    this.btnUp.addEventListener('click', (e) => {
      if (this.activeSection <= this.sections.length && this.activeSection > 1) {
        this.activeSection--;
      }
    });

    this.btnDown.addEventListener('click', (e) => {
      if (this.activeSection > 0 && this.activeSection < this.sections.length) {
        this.activeSection++;
      }
    });

    this.buttons.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        this.changeDots();
        this.scrollToSection();
      });
    });
  }

  keyboardEvents() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 40) {
        if (this.activeSection > 0 && this.activeSection < this.sections.length) {
          this.activeSection++;
          this.changeDots();
          this.scrollToSection();
        }
      }
      if (e.keyCode == 38) {
        if (this.activeSection <= this.sections.length && this.activeSection > 1) {
          this.activeSection--;
          this.changeDots();
          this.scrollToSection();
        }
      }
    });
  }

  changeDots() {
    this.dots.forEach((dot) => {
      dot.classList.remove('active');
      if (this.activeSection == dot.getAttribute('data-target-section')) {
        dot.classList.add('active');
      }
    });

    if (this.activeSection == this.sections.length) {
      this.btnDown.style.visibility = 'hidden';
    } else {
      this.btnDown.style.visibility = 'visible';
    }

    if (this.activeSection == 1) {
      this.btnUp.style.visibility = 'hidden';
    } else {
      this.btnUp.style.visibility = 'visible';
    }
  }

  scrollToSection() {
    let scrollTarget = document.querySelector(`[data-section="${this.activeSection}"]`);
    this.scroller.scrollTo(0, scrollTarget.offsetTop);
  }

  calculateRange() {
    this.overlayRange = [];

    this.sections.forEach((section, index) => {
      let firstSlide = section.querySelectorAll('.p-scroll__slide')[0];
      let start = firstSlide.offsetTop + this.scrollOffset;
      let end = section.offsetTop + section.offsetHeight - firstSlide.offsetHeight + this.scrollOffset;

      this.overlayRange.push({
        start: Math.round(start),
        end: Math.round(end),
      });
    });
  }

  initArchiveProjects() {
    const scrollButton = document.querySelector('.p-archive-project__indicator');

    scrollButton.addEventListener('click', () => {
      this.scrollContainer.scrollLeft += 480;
    });

    this.scrollContainer.addEventListener('wheel', (evt) => {
      evt.preventDefault();
      this.scrollContainer.scrollLeft += evt.deltaY;
    });
  }
}
